import React from 'react'
import Layout from '../layouts/layout'
import Background from '../components/background'

function GravityMap() {
  return (
    <Layout>
      <div style={{ padding: 20 }}>
        <Background />

        <h1>This is a Gravity Map embed.</h1>

        <iframe
          src="https://gravitymap.bike/embed?token=pk.eyJ1Ijoid2lsYm8iLCJhIjoiY20zYTdwa2VsMTV6cDJqcjZwYnd3ZmI3byJ9.DAIJJ6wXmwrGFZUvymN8Lg&z=2"
          // src="http://localhost:3000/embed?token=pk.eyJ1Ijoid2lsYm8iLCJhIjoiY20zYTdwa2VsMTV6cDJqcjZwYnd3ZmI3byJ9.DAIJJ6wXmwrGFZUvymN8Lg&z=2"
          width="100%"
          height="500px"
          frameborder="0"
        />
      </div>

      <div style={{ padding: 20 }}>
        <p>
          Making sure only /embed can be embedded (this should not render
          anything).
        </p>
        <iframe
          src="https://gravitymap.bike/map/11.009225835506982/46.95942028621738/16/0?z=17"
          // src="http://localhost:3000/map/11.009225835506982/46.95942028621738/16/0?z=17"
          width="100%"
          height="500px"
          frameborder="0"
        />
      </div>
    </Layout>
  )
}

export default GravityMap
